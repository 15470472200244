import { Modal } from "antd";

const { confirm } = Modal;

export const deleteConfirm = ({
  type,
  name,
  onDelete,
  message,
  confirmLoading,
}) => {
  const title = message || `Anda yakin akan menghapus ${type} ${name || ""} ?`;
  confirm({
    title,
    okText: "Ya",
    okType: "danger",
    cancelText: "Batal",
    confirmLoading,
    onOk() {
      onDelete();
    },
  });
};
