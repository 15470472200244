import React from "react";
import { Card, Form, Input, Button, Alert } from "antd";
import { Link } from "react-router-dom";

const View = ({ handleLogin, isLoading, error }) => {
  const [form] = Form.useForm();

  return (
    <Card bordered={false} style={{ width: 350 }}>
      <h3>Login</h3>
      {error && (
        <Alert message={error} type="error" className="mb-2" showIcon />
      )}
      <Form layout="vertical" form={form} onFinish={handleLogin}>
        <Form.Item label="Email" name="email" required>
          <Input type="email" />
        </Form.Item>
        <Form.Item label="Password" name="password" required>
          <Input.Password />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" loading={isLoading}>
            Login
          </Button>
        </Form.Item>
      </Form>
      <div>
        <Link to="/auth/login">Daftar / Masuk dengan Google</Link>
      </div>
    </Card>
  );
};

export default View;
