import React from "react";
import { img } from "assets";

function index({ isAdmin, text }) {
  if (isAdmin) {
    return (
      <div className="row">
        <div className="align-items-center col-12 d-flex">
          <div>
            <img
              src={img.logo}
              alt="logo"
              className="mr-2"
              style={{ height: 17 }}
            />
          </div>
          <small>{text || "Copyright © PSB 2020."}</small>
        </div>
      </div>
    );
  }
  return (
    <div className="container">
      <div className="row">
        <div className="align-items-center col-12 d-flex">
          <div>
            <img
              src={img.logo}
              alt="logo"
              className="mr-2"
              style={{ height: 17 }}
            />
          </div>
          <small>{text || "Copyright © PSB 2020."}</small>
        </div>
      </div>
    </div>
  );
}

export default index;
