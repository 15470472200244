import React, { useRef, useState, useEffect } from "react";
import { API } from "config";
import { FileService } from "services";
import { message } from "antd";
import { useHistory, useParams } from "react-router-dom";

import axios from "axios";
import View from "./user-form-view";

function Controller() {
  const source = useRef(null);
  const history = useHistory();
  const [isLoading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const { id } = useParams();
  const isEdit = typeof id !== "undefined";

  const _handleSubmit = async (values) => {
    try {
      source.current = axios.CancelToken.source();
      setLoading(true);

      if (values.pic?.length > 0) {
        const { originFileObj, name } = values.pic[0];
        const filename = `picture/${new Date().toISOString()}_${name}`;
        const data = await FileService.upload(
          source.current.token,
          originFileObj,
          filename
        );
        values.picture = data.url;
        delete values.pic;
      }

      if (isEdit) {
        await API.user.update(source.current.token, id, values);
        message.success("Data berhasil diubah");
      } else {
        values.role = "validator";
        await API.user.create(source.current.token, values);
        message.success("Data berhasil disimpan");
      }

      setLoading(false);
      history.push("/dashboard/user");
    } catch (error) {
      setLoading(false);
      console.error("error: ", error);
    }
  };

  useEffect(() => {
    const _loadData = async () => {
      source.current = axios.CancelToken.source();
      try {
        setLoading(true);

        const data = await API.user.getByID(source.current.token, id);
        console.log("data: ", data);
        [
          "comments",
          "createdAt",
          "is_active",
          "materials",
          "rating_histories",
          "updatedAt",
          "password",
        ].forEach((key) => {
          delete data[key];
        });

        setData(data);

        setLoading(false);
      } catch (error) {
        console.error("error: ", error);
        setLoading(false);
      }
    };
    if (id) _loadData();
  }, [id]);

  useEffect(() => {
    return () => {
      if (source.current) {
        source.current.cancel("request canceled");
      }
    };
  }, []);
  return (
    <View
      handleSubmit={_handleSubmit}
      isLoading={isLoading}
      isEdit={isEdit}
      data={data}
    />
  );
}

export default Controller;
