import React from "react";
import { Tag } from "antd";
import classnames from "classnames";
import { MATERIAL_TYPE_VALUE, MATERIAL_TYPE_COLOR } from "constant";

function index({ type, className }) {
  let color = "";
  try {
    color = MATERIAL_TYPE_COLOR[type];
  } catch (err) {
    color = MATERIAL_TYPE_COLOR["0"];
  }
  return (
    <Tag color={color} className={classnames("m-0 text-capitalize", className)}>
      {MATERIAL_TYPE_VALUE[type] || ""}
    </Tag>
  );
}

export default index;
