export const MATERIAL_TYPE_VALUE = {
  0: "Book",
  1: "Video",
  2: "Audio",
  3: "HTML5",
};

export const MATERIAL_TYPE = {
  BOOK: 0,
  VIDEO: 1,
  AUDIO: 2,
  HTML: 3,
};

export const MATERIAL_STATUS = {
  0: "Pending",
  1: "Approved",
  2: "Reject",
};

export const ROLES = {
  admin: "Admin",
  subscriber: "Subscriber",
  contributor: "Contributor",
  validator: "Validator",
};

export const MATERIAL_TYPE_COLOR = {
  [MATERIAL_TYPE.BOOK]: "#2492f8",
  [MATERIAL_TYPE.VIDEO]: "#f15a24",
  [MATERIAL_TYPE.AUDIO]: "#11aed0",
  [MATERIAL_TYPE.HTML]: "#ee38bb",
};
