import React from "react";
import { Switch, Route } from "react-router-dom";
import { routes } from "config";

function index() {
  return (
    <div className="vh-100 vw-100 bg-light d-flex justify-content-center align-items-center">
      <Switch>
        {routes.adminAuth.map((route) => (
          <Route
            path={route.path}
            component={route.component}
            key={route.path}
          />
        ))}
      </Switch>
    </div>
  );
}

export default index;
