import React, { useContext, useEffect } from "react";
import { Layout } from "antd";
import { Header } from "containers";
import { Footer } from "components";
import { Switch, Route } from "react-router-dom";
import { routes } from "config";
import { PrivateRoute } from "components";
import { SiteSettingContext } from "context/site-setting";
import { API } from "config";
import { AuthContext } from "context/auth";

function Component() {
  const siteCtx = useContext(SiteSettingContext);
  const authCtx = useContext(AuthContext);
  useEffect(() => {
    if (authCtx.isLogin()) API.auth.check();
  }, [authCtx]);
  return (
    <Layout>
      <Layout.Header className="bg-light">
        <Header />
      </Layout.Header>
      <Layout.Content
        className="bg-white"
        style={{ minHeight: "calc(100vh - 134px)" }}
      >
        <Switch>
          {routes.main.map((route) => {
            if (route.isPublic) {
              return (
                <Route
                  path={route.path}
                  component={route.component}
                  key={route.path}
                />
              );
            }
            return (
              <PrivateRoute
                path={route.path}
                component={route.component}
                key={route.path}
                roles={route.roles}
              />
            );
          })}
        </Switch>
      </Layout.Content>
      <Layout.Footer className="bg-light" style={{ zIndex: 2 }}>
        <Footer text={siteCtx?.value?.footer_text} />
      </Layout.Footer>
    </Layout>
  );
}

export default Component;
