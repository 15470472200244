import Ads from "./ads";
import AdsForm from "./ads-form";
import Banner from "./banner";
import BannerForm from "./banner-form";
import Instrument from "./instrument";
import InstrumentForm from "./instrument-form";
import Login from "./login";
import Material from "./material";
import MaterialForm from "./material-form";
import Notification from "./notification";
import SiteSetting from "./site-setting";
import User from "./user";
import UserForm from "./user-form";

export default {
  Ads,
  AdsForm,
  Banner,
  BannerForm,
  Instrument,
  InstrumentForm,
  Login,
  Material,
  MaterialForm,
  Notification,
  SiteSetting,
  User,
  UserForm,
};
