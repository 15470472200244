import React from "react";
import { Comment, RatingBox } from "containers";
import { Avatar, Rate, Button, Space, Divider, Spin } from "antd";
import { ContentViewer, TypeTag } from "components";
import { img } from "assets";
import { MATERIAL_TYPE_VALUE, MATERIAL_TYPE } from "constant";
import { Link } from "react-router-dom";

function View({ data, isLoading, reload }) {
  return (
    <Spin spinning={isLoading}>
      <div className="mb-5">
        <div className="bg-primary">
          <div
            className="container align-items-center d-flex"
            style={{ height: 150 }}
          >
            <div className="d-flex">
              <div style={{ minWidth: 100 }} className="mr-3">
                <img
                  style={{
                    height: 92,
                    objectFit: "cover",
                    borderRadius: 8,
                    border: "solid 8px rgba(255, 255, 255, 0.12)",
                  }}
                  alt="example"
                  src={data?.cover_link}
                />
              </div>
              <div className="d-flex flex-column justify-content-between">
                <span className="text-light">
                  Home / Kategori / {MATERIAL_TYPE_VALUE[data?.type]} /{" "}
                  {data?.title}
                </span>

                <span
                  style={{ fontSize: 24, fontWeight: "bold" }}
                  className="text-white"
                >
                  {data?.title}
                </span>
                <div className="d-flex align-items-center">
                  <TypeTag type="video" className="mr-2" />
                  <Rate
                    value={data?.rating}
                    className="ant-rate-sm mr-2"
                    disabled
                  />
                  <span className="text-light">{data?.view_count} Views</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row my-3">
            <div className="col-12 d-flex justify-content-between">
              <div className="d-flex align-items-center">
                <div className="mr-2">
                  <Avatar
                    size={55}
                    src={data?.created_by?.picture || img.user}
                  />
                </div>
                <div className="d-flex justify-content-between flex-column">
                  <b className="text-capitalize">{data?.created_by?.name}</b>
                  <span style={{ fontSize: 12 }} className="text-primary">
                    {data?.created_by?.job || "-"}
                  </span>
                  <span style={{ fontSize: 12 }}>
                    {data?.material_count} Materi
                  </span>
                </div>
              </div>
              <Space>
                <Link to={`/contributor/${data?.created_by?.id}`}>
                  <Button className="bg-dark text-white">
                    Profile Kontibutor
                  </Button>
                </Link>
                {parseInt(data?.type) === MATERIAL_TYPE.BOOK && (
                  <Button target="_blank" href={data?.content} type="primary">
                    Download
                  </Button>
                )}
              </Space>
            </div>
            <div className="col-12">
              <Divider />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <p className="font-weight-bold">Overview</p>
              <p className="text-muted">{data?.overview}</p>
              <ContentViewer content={data?.content} type={data?.type} />
              <Divider />
            </div>
          </div>
          <div className="row">
            <div className="col-9" id="comment">
              <p className="psb-title">Komentar</p>
              <Comment materialId={data?.id} />
            </div>
            <div className="col-3" id="rating">
              <p className="psb-title">Rating</p>
              <RatingBox
                reload={reload}
                rating={data?.rating}
                summary={data?.rating_summary}
                materialId={data?.id}
              />
            </div>
          </div>
        </div>
      </div>
    </Spin>
  );
}

export default View;
