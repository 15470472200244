import React, { useEffect } from "react";
import { Card, Form, Input, Button, Upload } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload } from "@fortawesome/free-solid-svg-icons";
import { normFile } from "utils";

function View({ handleSubmit, isLoading, isEdit, data }) {
  const [form] = Form.useForm();

  useEffect(() => {
    if (data) form.setFieldsValue({ ...data });
  }, [data, form]);

  return (
    <div className="row">
      <div className="col-12">
        <Card title="Banner" bordered={false}>
          <Form layout="vertical" form={form} onFinish={handleSubmit}>
            {/* <Form.Item label="Judul" name="title">
              <Input />
            </Form.Item> */}
            {/* <Form.Item label="Sub Judul" name="subtitle">
              <Input />
            </Form.Item> */}
            <Form.Item label="Gambar" required={!isEdit}>
              {data?.image_link && (
                <img
                  src={data.image_link}
                  className="img-thumbnail d-block mb-1"
                  alt=""
                />
              )}
              <Form.Item
                name="image"
                valuePropName="fileList"
                getValueFromEvent={normFile}
                required={!isEdit}
                label="Gambar"
                noStyle
              >
                <Upload
                  accept="image/*"
                  beforeUpload={() => false}
                  multiple={false}
                >
                  <Button>
                    <FontAwesomeIcon icon={faUpload} className="mr-2" />
                    {data?.image_link ? "Ganti Gambar" : "Pilih Gambar"}
                  </Button>
                </Upload>
              </Form.Item>
            </Form.Item>
            <Form.Item label="CTA Text" name="cta_text">
              <Input />
            </Form.Item>

            <Form.Item label="CTA Link" name="cta_link">
              <Input />
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit" loading={isLoading}>
                Simpan
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </div>
    </div>
  );
}

export default View;
