import React from "react";
import { useCookies } from "react-cookie";

export const AuthContext = React.createContext({});

export const AuthProvider = ({ children, ...props }) => {
  const [cookies, setCookie, removeCookie] = useCookies(["psbUserData"]);

  const setToken = (value, daysExpire = 7) => {
    var expires = new Date();
    expires.setDate(expires.getDate() + daysExpire);

    setCookie("psbUserData", value, { expires, path: "/" });
  };

  const removeToken = () => {
    removeCookie("psbUserData", { path: "/" });
  };

  const isLogin = () => {
    if (typeof cookies.psbUserData !== "undefined") {
      return true;
    }
    return false;
  };

  const isAuthorized = (roles) => {
    if (typeof cookies.psbUserData === "undefined") {
      return false;
    }
    if (roles.includes(cookies.psbUserData.role)) {
      return true;
    }
    return false;
  };

  const value = {
    login: setToken,
    logout: removeToken,
    user: cookies.psbUserData,
    role: cookies.psbUserData?.role,
    isLogin,
    isAuthorized,
  };

  return (
    <AuthContext.Provider value={value} {...props}>
      {children}
    </AuthContext.Provider>
  );
};
