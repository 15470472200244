import React from "react";
import { Card, Alert, Spin } from "antd";
import { GoogleLogin } from "react-google-login-component";
import { Link } from "react-router-dom";

const View = ({ handleLogin, isLoading, error }) => {
  return (
    <Card bordered={false} style={{ width: 350 }}>
      {error && (
        <Alert message={error} type="error" className="mb-2" showIcon />
      )}

      <div className="pt-3 d-flex align-items-center flex-column">
        {isLoading && <Spin />}
        <GoogleLogin
          socialId="148449909881-q9qa8fr4m7htvhmrnn05ro9mllp5m9o2.apps.googleusercontent.com"
          className="loginBtn loginBtn--google mb-4"
          scope="profile"
          prompt="select_account"
          fetchBasicProfile={true}
          responseHandler={handleLogin}
          buttonText="Daftar / Masuk dengan Google"
          disabled={isLoading}
        />

        <Link to="/authp/login" className="mb-2">
          Masuk dengan email dan password
        </Link>
      </div>
    </Card>
  );
};

export default View;
