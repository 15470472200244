import React from "react";
import { useState } from "react";

export const SiteSettingContext = React.createContext({});

export const SiteSettingProvider = ({ children, ...props }) => {
  const [value, setValue] = useState({
    favicon_link: null,
    footer_text: null,
    ga: null,
    logo_link: null,
    meta_description: null,
    meta_keyword: null,
    site_name: null,
  });

  const values = {
    setValue,
    value,
  };

  return (
    <SiteSettingContext.Provider value={values} {...props}>
      {children}
    </SiteSettingContext.Provider>
  );
};
