import React, { useRef, useState, useEffect } from "react";
import axios from "axios";
import View from "./site-setting-view";
import { useQuery } from "react-query";
import { API } from "config";
import { FileService } from "services";
import { message } from "antd";

function Controller() {
  const source = useRef(null);

  const [isLoading, setLoading] = useState(false);
  const settingQuery = useQuery("setting", () => {
    source.current = axios.CancelToken.source();
    return API.setting.get(source.current.token);
  });

  const _update = async (values) => {
    console.log("values: ", values);
    try {
      source.current = axios.CancelToken.source();
      setLoading(true);

      if (values.logo?.length > 0) {
        const { originFileObj, name } = values.logo[0];
        const ext = name.split(".").pop();
        const filename = "logo." + ext;
        const data = await FileService.upload(
          source.current.token,
          originFileObj,
          filename
        );
        values.logo_link = data.url;
        delete values.logo;
      }

      if (values.favicon?.length > 0) {
        const { originFileObj, name } = values.favicon[0];
        const ext = name.split(".").pop();
        const filename = "favicon." + ext;
        const data = await FileService.upload(
          source.current.token,
          originFileObj,
          filename
        );
        values.favicon_link = data.url;
        delete values.favicon;
      }

      await API.setting.update(source.current.token, values);

      setLoading(false);
      message.success("Berhasil diperbaharui");
      settingQuery.refetch();
    } catch (error) {
      setLoading(false);
      console.error("error: ", error);
    }
  };

  useEffect(() => {
    return () => {
      if (source.current) {
        source.current.cancel("request canceled");
      }
    };
  }, []);

  return (
    <View
      handleSubmit={_update}
      isLoading={isLoading}
      settingQuery={settingQuery}
    />
  );
}

export default Controller;
