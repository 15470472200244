import React, { useRef, useState } from "react";
import { useQuery } from "react-query";
import axios from "axios";
import { API } from "config";
import View from "./notification-view";
import { message } from "antd";

function Controller() {
  const source = useRef(null);
  const [lastID, setLastID] = useState(null);
  source.current = axios.CancelToken.source();

  const query = useQuery(
    "notification",
    async () => {
      const rsp = await API.notification.list(source.current.token);
      const lastNotif = rsp[rsp.length - 1];
      setLastID(lastNotif.id || null);

      return rsp;
    },
    { initialData: [], initialStale: true }
  );

  const _handleMarkHasRead = async () => {
    await API.notification.markHasRead(lastID);
    message.success("Berhasil menandai sudah dibaca");
    query.refetch();
  };

  return <View query={query} markHasRead={_handleMarkHasRead} />;
}

export default Controller;
