import React, { useRef, useState, useEffect } from "react";
import axios from "axios";
import View from "./comment-view";
import { API } from "config";
import { message } from "antd";

function Controller({ materialId }) {
  const source = useRef(null);
  const [isLoading, setLoading] = useState(false);
  const [comments, setComments] = useState([]);
  const [reloadCount, setReloadCount] = useState(0);
  const _handleSubmit = async (values) => {
    try {
      setLoading(true);
      source.current = axios.CancelToken.source();
      await API.comment.create(
        source.current.token,
        materialId,
        values.content
      );
      message.success("Komentar berhasil dikirim");
      setReloadCount(reloadCount + 1);
      setLoading(false);
    } catch (error) {
      console.log("error: ", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    const _loadData = async () => {
      source.current = axios.CancelToken.source();
      try {
        setLoading(true);

        const data = await API.comment.listByMaterialID(
          source.current.token,
          materialId
        );

        setComments(data);
        setLoading(false);
      } catch (error) {
        console.error("error: ", error);
        setLoading(false);
      }
    };
    if (materialId) _loadData();
  }, [materialId, reloadCount]);

  useEffect(() => {
    return () => {
      if (source.current) {
        source.current.cancel("request canceled");
      }
    };
  }, []);

  return (
    <View
      handleSubmit={_handleSubmit}
      isLoading={isLoading}
      comments={comments}
    />
  );
}

export default Controller;
