import React from "react";
import { Card, Rate } from "antd";
import { TypeTag } from "components";
import { Link } from "react-router-dom";

const LENGTH_MAX = 130;

function index({ slug, cover, type, viewCount, title, description, rating }) {
  description = description || "";
  const _useEllipsis = (text) => {
    if (!text) return false;

    if (text.length < LENGTH_MAX) return false;

    return true;
  };
  return (
    <Card
      bordered={false}
      bodyStyle={{ padding: 0 }}
      cover={
        <img
          style={{ height: 175, objectFit: "cover" }}
          alt="example"
          src={cover}
        />
      }
    >
      <div className="d-flex justify-content-between align-items-center mt-1">
        <div>
          <small className="text-warning mr-1">{rating}</small>
          <Rate className="ant-rate-sm" disabled defaultValue={rating} />
        </div>
        <TypeTag type={type} />
      </div>
      <div>
        <small className="text-muted">{viewCount} Views</small>
      </div>
      <Link
        to={`/material/${slug}`}
        style={{ fontSize: 16, fontWeight: 600 }}
        className="text-psb-dark"
      >
        {title}
      </Link>
      <div>
        <small>{`${description.substring(0, LENGTH_MAX)} ${
          _useEllipsis(description) ? "..." : ""
        }`}</small>
      </div>
    </Card>
  );
}

export default index;
