import React, { useRef } from "react";
import { Carousel, Button } from "antd";
import axios from "axios";
import { useQuery } from "react-query";
import { API } from "config";

function Component() {
  const source = useRef(null);
  source.current = axios.CancelToken.source();
  const query = useQuery(
    "banner",
    async () => {
      const rsp = await API.banner.list(source.current.token);
      return rsp.data;
    },
    { initialData: [], initialStale: true }
  );

  if (query.data.length === 0) {
    return null;
  }

  return (
    <Carousel autoplay={true} dotPosition="left">
      {query.data.map((val) => (
        <Item
          img={val.image_link}
          cta_text={val.cta_text}
          cta_link={val.cta_link}
          key={val.id}
        />
      ))}
    </Carousel>
  );
}

const Item = ({ img, cta_text, cta_link }) => (
  <div className="container-slider">
    <img src={img} alt="" className="img-fluid w-100" />
    {cta_text && cta_link && (
      <Button
        size="large"
        className="text-primary"
        href={cta_link}
        target="_blank"
        tag="a"
      >
        {cta_text}
      </Button>
    )}
  </div>
);

export default Component;
