import React, { useRef, useState } from "react";
import { useQuery } from "react-query";
import axios from "axios";
import { API } from "config";
import View from "./instrument-view";
import { vars } from "config";
import { message } from "antd";

function Controller() {
  const source = useRef(null);
  source.current = axios.CancelToken.source();
  const [metaPage, setMetaPage] = useState(vars.metaPage);
  const [isLoading, setLoading] = useState(false);

  const query = useQuery(
    "instrument",
    async () => {
      const rsp = await API.instrument.list(source.current.token);
      setMetaPage(rsp.meta);
      return rsp.data;
    },
    { initialData: [], initialStale: true }
  );

  const _handleDelete = async (id) => {
    try {
      setLoading(true);
      source.current = axios.CancelToken.source();
      await API.instrument.delete(source.current.token, id);
      setLoading(false);
      query.refetch();
      message.success("Data berhasil dihapus");
    } catch (error) {
      console.error("error: ", error);
      setLoading(false);
    }
  };

  return (
    <View
      query={query}
      metaPage={metaPage}
      onDelete={_handleDelete}
      isLoading={isLoading}
    />
  );
}

export default Controller;
