import React, { useEffect, useState } from "react";
import { Card, Form, Input, Button, InputNumber, Radio } from "antd";

function View({ handleSubmit, isLoading, isEdit, data }) {
  const [form] = Form.useForm();
  const [type, setType] = useState(null);

  useEffect(() => {
    if (data) form.setFieldsValue({ ...data });
  }, [data, form]);

  useEffect(() => {
    setType(data.type);
  }, [data]);

  const _handleValueChanges = ({ type }) => {
    if (typeof type !== "undefined") setType(type);
  };

  return (
    <div className="row">
      <div className="col-12">
        <Card title="Instrument" bordered={false}>
          <Form
            layout="vertical"
            form={form}
            onFinish={handleSubmit}
            onValuesChange={_handleValueChanges}
          >
            <Form.Item label="Tipe" name="type" required>
              <Radio.Group>
                <Radio value="terbuka">Terbuka</Radio>
                <Radio value="tertutup">Tertutup</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item label="Pertanyaan" name="question" required>
              <Input />
            </Form.Item>

            {type === "tertutup" && (
              <>
                <Form.Item
                  initialValue={3}
                  label="Nilai Maksimal"
                  name="value_end"
                  required
                >
                  <InputNumber />
                </Form.Item>

                <Form.Item label="Label Terendah" name="text_start">
                  <Input placeholder="Contoh: Sangat Buruk" />
                </Form.Item>

                <Form.Item label="Label Tertinggi" name="text_end">
                  <Input placeholder="Contoh: Sangat Baik" />
                </Form.Item>
              </>
            )}

            <Form.Item>
              <Button type="primary" htmlType="submit" loading={isLoading}>
                Simpan
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </div>
    </div>
  );
}

export default View;
