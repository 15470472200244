import React from "react";
import { Avatar, Space, Spin } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-regular-svg-icons";

import { Material } from "containers";
import { img } from "assets";
function View({ data, isLoading }) {
  return (
    <Spin spinning={isLoading}>
      <div className="mb-5">
        <div className="bg-primary">
          <div
            className="container align-items-center d-flex"
            style={{ height: 150 }}
          >
            <div className="d-flex">
              <div style={{ minWidth: 100 }} className="mr-3">
                <Avatar size={92} src={data.picture || img.user} />
              </div>
              <div className="d-flex flex-column justify-content-between">
                <span className="text-light">
                  Home / Kontributor / {data.name}
                </span>

                <span
                  style={{ fontSize: 24, fontWeight: "bold" }}
                  className="text-white"
                >
                  {data.name}
                </span>

                <Space className="text-white">
                  <b>{data.job}</b>{" "}
                  <span>
                    {" "}
                    <FontAwesomeIcon icon={faCircle} /> {data.materials?.length}{" "}
                    Materi
                  </span>
                </Space>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          {/* <div className="row my-4">
            <div className="col-12">
              <p className="psb-title">Tentang Kontributor</p>
              <p className="text-muted">
                Amet minim mollit non deserunt ullamco est sit aliqua dolor do
                amet sint. Velit officia consequat duis enim velit mollit.
                Exercitation veniam consequat sunt nostrud amet. Amet minim
                mollit non deserunt ullamco est sit aliqua dolor do amet sint.
                Velit officia consequat duis enim velit mollit. Exercitation
                veniam consequat sunt nostrud amet.
              </p>
            </div>
          </div> */}
          <div className="row my-4">
            <div className="col-12">
              <p className="psb-title mb-2">Materi</p>
              <Material contributorId={data.id} />
            </div>
          </div>
        </div>
      </div>
    </Spin>
  );
}

export default View;
