import React from "react";
import { MATERIAL_TYPE } from "constant";

const Youtube = ({ content }) => (
  <div className="iframe-container youtube">
    <iframe
      title="iframeyoutube"
      width="560"
      height="315"
      src={content}
      frameBorder="0"
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      className="iframe-content"
    ></iframe>
  </div>
);

const AnchorFM = ({ content }) => (
  <div className="iframe-container anchor">
    <iframe
      title="iframeanchor"
      src={content}
      height="102px"
      width="400px"
      frameBorder="0"
      scrolling="no"
      className="iframe-content"
    ></iframe>
  </div>
);

const PDF = ({ content }) => (
  <div className="iframe-container pdf">
    <embed
      src={`https://drive.google.com/viewerng/viewer?embedded=true&url=${content}`}
      style={{ width: "100%", height: 700 }}
      type="application/pdf"
    ></embed>
    {/* <iframe
      title="iframeanchor"
      src={content}
      height="102px"
      width="400px"
      frameBorder="0"
      scrolling="no"
      className="iframe-content"
    ></iframe> */}
  </div>
);

function Controller({ content, type }) {
  type = parseInt(type);

  if (type === MATERIAL_TYPE.VIDEO) {
    return <Youtube content={extractSrc(content)} />;
  }
  if (type === MATERIAL_TYPE.AUDIO) {
    return <AnchorFM content={extractSrc(content)} />;
  }

  if (type === MATERIAL_TYPE.BOOK) {
    return <PDF content={content} />;
  }
  return "";
}

export default Controller;

const extractSrc = (string) => {
  const match = string.match(/(?:src=\").*?(?=[\?"])/);

  if (!match) {
    return "";
  }

  if (match.length > 0) {
    return match[0].replace('src="', "");
  }
  return "";
};
