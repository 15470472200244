import React, { useRef } from "react";
import { useQuery } from "react-query";
import { Form, Input, Slider, Modal, Radio, Divider } from "antd";
import { ContentViewer } from "components";
import { API } from "config";
import axios from "axios";

function ModalValidasi({ onOk, onCancel, data }) {
  const source = useRef(null);
  const [form] = Form.useForm();
  source.current = axios.CancelToken.source();
  const query = useQuery(
    "instrument",
    async () => {
      const rsp = await API.instrument.list(source.current.token);
      return rsp.data;
    },
    { initialData: [], initialStale: true }
  );

  return (
    <Modal
      width={700}
      title="Validasi"
      visible={true}
      okText="Validasi"
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            form.resetFields();
            onOk(values);
          })
          .catch((info) => {
            console.log("Validate Failed:", info);
          });
      }}
      onCancel={onCancel}
    >
      <ContentViewer content={data.content} type={data.type} />
      <Divider />
      <h4>Hasil Validasi</h4>
      <Form layout="vertical" form={form}>
        {query.data.map((instrument) => {
          if (instrument.type === "terbuka") {
            return (
              <Form.Item
                key={instrument.id}
                label={instrument.question}
                name={`${instrument.id}_${instrument.question}`}
                required
              >
                <Input.TextArea />
              </Form.Item>
            );
          }
          return (
            <Form.Item
              key={instrument.id}
              label={instrument.question}
              name={`${instrument.id}_${instrument.question}_${instrument.text_start} (1) - ${instrument.text_end} (${instrument.value_end})`}
              required
              extra={`${instrument.text_start || 1} - ${
                instrument.text_end || instrument.value_end
              }`}
            >
              <Slider
                dots
                included={false}
                max={instrument.value_end}
                min={1}
              />
            </Form.Item>
          );
        })}
        <Form.Item label="Hasil Akhir" name="status" required>
          <Radio.Group>
            <Radio value="posted">Setujui</Radio>
            <Radio value="reject">Tolak</Radio>
          </Radio.Group>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default ModalValidasi;
