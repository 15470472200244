import React, { useContext, useEffect } from "react";
import { Layout } from "antd";
import { Header, Sidebar } from "containers";
import { Footer } from "components";
import { Switch, Route } from "react-router-dom";
import { routes } from "config";
import { PrivateRoute } from "components";
import { API } from "config";
import { AuthContext } from "context/auth";

function Component() {
  const authCtx = useContext(AuthContext);
  useEffect(() => {
    if (authCtx.isLogin()) API.auth.check();
  }, [authCtx]);
  return (
    <Layout>
      <Layout.Sider>
        <Sidebar />
      </Layout.Sider>
      <Layout>
        <Layout.Header className="bg-white">
          <Header isAdmin />
        </Layout.Header>
        <Layout.Content
          style={{ minHeight: "calc(100vh - 134px)" }}
          className="bg-light-grey"
        >
          <div className="container my-2 my-md-4 ">
            <Switch>
              {routes.admin.map((route) => {
                if (route.isPublic) {
                  return (
                    <Route
                      path={route.path}
                      component={route.component}
                      key={route.path}
                    />
                  );
                }
                return (
                  <PrivateRoute
                    path={route.path}
                    component={route.component}
                    key={route.path}
                    roles={route.roles}
                  />
                );
              })}
            </Switch>
          </div>
        </Layout.Content>
        <Layout.Footer className="bg-light">
          <Footer isAdmin />
        </Layout.Footer>
      </Layout>
    </Layout>
  );
}

export default Component;
