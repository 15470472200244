import React, { useRef, useContext } from "react";
import { Divider, message, Progress, Rate, Tooltip } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { AuthContext } from "context/auth";
import { API } from "config";
import axios from "axios";

const DEFAULT_RATE_SUM = {
  1: 0,
  2: 0,
  3: 0,
  4: 0,
  5: 0,
};

function View({ summary, rating, materialId, reload }) {
  const authCtx = useContext(AuthContext);
  const source = useRef(null);

  const _handleRate = async (rate) => {
    try {
      source.current = axios.CancelToken.source();
      await API.material.rate(source.current.token, materialId, rate);
      message.success("Berhasil memberikan rating");
      reload();
    } catch (error) {
      console.log("error: ", error);
    }
  };

  return (
    <div>
      <div className="text-muted">Rating Keseluruhan</div>
      <div className="d-flex justify-content-center">
        <Rate value={rating} className="ant-rate-lg" disabled />
      </div>
      <Divider />
      <div>
        <RateSummary value={summary} />
      </div>
      <Tooltip title={authCtx.isLogin() ? "" : "Masuk untuk memberikan rating"}>
        <div className="font-weight-bold mt-4">Rating Dari Anda</div>
        <div className="d-flex justify-content-center">
          <Rate
            className="ant-rate-lg"
            disabled={!authCtx.isLogin()}
            onChange={_handleRate}
          />
        </div>
      </Tooltip>
    </div>
  );
}

const RateSummary = ({ value }) => {
  value = value || DEFAULT_RATE_SUM;
  const total = Object.values(value).reduce((a, b) => a + b, 0);
  return (
    <div>
      {Object.keys(value).map((key) => (
        <RateBar point={key} key={key} value={value[key]} total={total} />
      ))}
    </div>
  );
};
const RateBar = ({ point, total, value }) => {
  const percent = (value / total) * 100;
  return (
    <div className="d-flex justify-content-between align-items-center">
      <div>
        <FontAwesomeIcon
          icon={faStar}
          className="text-rate mr-1"
          style={{ fontSize: 16 }}
        />
        <span>{point}</span>
      </div>
      <div style={{ width: "calc(100% - 100px)" }}>
        <Progress percent={percent} showInfo={false} />
      </div>

      <span className="text-right" style={{ minWidth: 30 }}>
        {value}
      </span>
    </div>
  );
};

export default View;
