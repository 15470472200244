import React from "react";
import { Form, Modal, Button } from "antd";

function ModalResult({ toggle, result }) {
  result = JSON.parse(result || {});
  return (
    <Modal
      title="Hasil Validasi"
      visible={true}
      okText="Tutup"
      cancelText={false}
      onCancel={toggle}
      footer={[
        <Button key="back" onClick={toggle}>
          Tutup
        </Button>,
      ]}
    >
      <Form layout="vertical">
        {Object.keys(result).map((key) => (
          <div>
            <b>{key} :</b> {result[key]}
          </div>
        ))}
      </Form>
    </Modal>
  );
}

export default ModalResult;
