import "antd/dist/antd.css";
import styled from "styled-components";

export const SidebarWrapper = styled.div`
  .containers-sidebar-logout {
    /*  */
    width: 100%;
    text-align: center;
    /* position: absolute; */
    bottom: 0;
    color: var(--primary);
    cursor: pointer;
    padding: 15px;
    font-weight: 600;
    font-size: 14px;
  }
`;
