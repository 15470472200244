import React, { useContext } from "react";
import { Avatar, Menu, Dropdown, Button, Input, Modal, message } from "antd";
import { img } from "assets";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faSearch } from "@fortawesome/free-solid-svg-icons";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useQueryParam, StringParam } from "use-query-params";
import { useState } from "react";
import { useEffect } from "react";
import { AuthContext } from "context/auth";
const { confirm } = Modal;

const menu = (
  <Menu>
    <Menu.Item>
      <Link to="/material?type=book-true">Book</Link>
    </Menu.Item>
    <Menu.Item>
      <Link to="/material?type=video-true">Video</Link>
    </Menu.Item>
    <Menu.Item>
      <Link to="/material?type=audio-true">Audio</Link>
    </Menu.Item>
    <Menu.Item>
      <Link to="/material?type=html-true">HTML</Link>
    </Menu.Item>
  </Menu>
);

function Component({ isAdmin }) {
  const history = useHistory();
  const [searchText, setSearchText] = useState(null);
  const [qSearchText] = useQueryParam("search", StringParam);
  const authCtx = useContext(AuthContext);
  const location = useLocation();

  const _handleChange = (e) => {
    const { value } = e.target;
    setSearchText(value);
  };
  const _handleSearch = (e) => {
    const { value } = e.target;
    setSearchText(value);
    history.push(`/material?search=${searchText}`);
  };

  useEffect(() => {
    if (qSearchText && location.pathname === "/material") {
      setSearchText(qSearchText);
    }
  }, [location.pathname, qSearchText]);

  if (isAdmin) {
    return (
      <div className="row">
        <div className="align-items-center col-12 d-flex justify-content-between">
          <Link to="/dashboard">
            <img src={img.logo} alt="logo" className="mr-2" />
          </Link>
        </div>
      </div>
    );
  }
  return (
    <div className="container">
      <div className="row">
        <div className="align-items-center col-12 d-flex justify-content-between">
          <Link to="/">
            <img src={img.logo} alt="logo" className="mr-2" />
          </Link>

          <div className="d-none d-md-block">
            <Dropdown overlay={menu} placement="bottomCenter" arrow>
              <Button type="link" className="text-dark">
                Jenis Materi{" "}
                <FontAwesomeIcon icon={faChevronDown} className="ml-1" />
              </Button>
            </Dropdown>
          </div>
          <Input
            prefix={<FontAwesomeIcon icon={faSearch} className="mr-1" />}
            placeholder="Cari materi (tekan enter untuk cari)"
            className="d-none d-md-flex"
            onPressEnter={_handleSearch}
            onChange={_handleChange}
            value={searchText}
          />
          <div className="ml-3 d-none d-md-block">
            {authCtx.isLogin() ? (
              <Dropdown
                overlay={
                  <Menu>
                    <Menu.Item>
                      <Link to="/dashboard">Go To Dashboard</Link>
                    </Menu.Item>
                    <Menu.Item>
                      <Link to="/profile">Profile</Link>
                    </Menu.Item>

                    <Menu.Item
                      onClick={() =>
                        confirm({
                          title: "Anda yakin akan keluar?",
                          okText: "Ya",
                          okType: "danger",
                          cancelText: "Batal",
                          onOk() {
                            authCtx.logout();
                            message.success("Anda berhasil keluar");
                          },
                        })
                      }
                    >
                      Logout
                    </Menu.Item>
                  </Menu>
                }
              >
                <Avatar className="cursor-pointer" src={authCtx.user.picture} />
              </Dropdown>
            ) : (
              <Link to="/auth/login">
                <Button type="primary" style={{ width: 100 }}>
                  Masuk
                </Button>
              </Link>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Component;
