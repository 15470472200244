import React, { useEffect } from "react";
import { Card, Form, Input, Button, Upload } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload } from "@fortawesome/free-solid-svg-icons";
import { normFile } from "utils";

function View({ handleSubmit, isLoading, settingQuery }) {
  const [form] = Form.useForm();

  useEffect(() => {
    if (settingQuery.data) form.setFieldsValue({ ...settingQuery.data });
  }, [settingQuery, form]);
  return (
    <div className="row">
      <div className="col-12">
        <Card title="Site Setting" bordered={false}>
          <Form layout="vertical" form={form} onFinish={handleSubmit}>
            <Form.Item label="Site Name" name="site_name" required>
              <Input placeholder="Nama Situs" />
            </Form.Item>
            <Form.Item label="Footer Text" name="footer_text" required>
              <Input />
            </Form.Item>
            <Form.Item label="Logo">
              {settingQuery.data?.logo_link && (
                <img
                  src={settingQuery.data.logo_link}
                  className="img-thumbnail d-block mb-1"
                  alt=""
                />
              )}
              <Form.Item
                name="logo"
                valuePropName="fileList"
                getValueFromEvent={normFile}
                required
                label="Logo"
                noStyle
              >
                <Upload
                  accept="image/*"
                  beforeUpload={() => false}
                  multiple={false}
                >
                  <Button>
                    <FontAwesomeIcon icon={faUpload} className="mr-2" />
                    {settingQuery.data?.logo_link ? "Ganti Logo" : "Pilih Logo"}
                  </Button>
                </Upload>
              </Form.Item>
            </Form.Item>

            <Form.Item label="Favicon">
              {settingQuery.data?.favicon_link && (
                <img
                  src={settingQuery.data.favicon_link}
                  className="img-thumbnail d-block mb-1"
                  alt=""
                />
              )}
              <Form.Item
                name="favicon"
                valuePropName="fileList"
                getValueFromEvent={normFile}
                required
                label="Logo"
                noStyle
              >
                <Upload
                  accept="image/*"
                  beforeUpload={() => false}
                  multiple={false}
                >
                  <Button>
                    <FontAwesomeIcon icon={faUpload} className="mr-2" />
                    {settingQuery.data?.favicon_link
                      ? "Ganti Favicon"
                      : "Pilih Favicon"}
                  </Button>
                </Upload>
              </Form.Item>
            </Form.Item>

            <Form.Item label="Meta Description" name="meta_description">
              <Input.TextArea rows={4} />
            </Form.Item>

            <Form.Item label="Meta Keyword" name="meta_keyword">
              <Input />
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit" loading={isLoading}>
                Update
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </div>
    </div>
  );
}

export default View;
