import React, { useContext } from "react";
import { AuthContext } from "context/auth";
import { Route, Redirect } from "react-router-dom";
import { message } from "antd";

const PrivateRoute = ({ roles, ...props }) => {
  const auth = useContext(AuthContext);

  if (process.env.REACT_APP_DISABLE_PRIVATE_ROUTE === "true") {
    return <Route {...props} />;
  }

  if (!auth.isLogin()) {
    if (props.path.startsWith("/dashboard")) {
      return <Redirect to="/authp/login" />;
    }

    return <Redirect to="/" />;
  }

  if (auth.isAuthorized(roles)) {
    return <Route {...props} />;
  }

  message.error("Anda tidak punya akses");
  return <Redirect to="/" />;
};

export default PrivateRoute;
