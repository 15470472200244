import React, { useEffect } from "react";
import { Avatar, Button, Space, Form, Input } from "antd";
import { img } from "assets";

function View({ data, isLoading, handleSubmitProfile }) {
  const [form] = Form.useForm();
  useEffect(() => {
    if (data) form.setFieldsValue({ ...data });
  }, [data, form]);
  return (
    <div className="mb-5">
      <div className="bg-primary">
        <div
          className="container align-items-center d-flex justify-content-between"
          style={{ height: 150 }}
        >
          <div className="d-flex">
            <div style={{ minWidth: 100 }} className="mr-3">
              <Avatar size={95} src={data.picture || img.user} />
            </div>
            <div className="d-flex flex-column justify-content-between">
              <span className="text-light">Home / Profile</span>

              <span
                style={{ fontSize: 24, fontWeight: "bold" }}
                className="text-white"
              >
                {data.name}
              </span>

              <Space className="text-white">
                <b>{data.job}</b>
              </Space>
            </div>
          </div>
          <div>
            <Button>Logout</Button>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row my-4">
          <div className="col-12">
            <p className="psb-title">Informasi Akun</p>
          </div>
        </div>
        <div className="row my-4">
          <div className="col-6">
            <Form layout="vertical" form={form} onFinish={handleSubmitProfile}>
              <Form.Item label="Nama" name="name" required>
                <Input />
              </Form.Item>
              <Form.Item label="Email" name="email" disabled>
                <Input disabled />
              </Form.Item>
              <Form.Item label="Pekerjaan" name="job" required>
                <Input />
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit" loading={isLoading}>
                  Update Profile
                </Button>
              </Form.Item>
            </Form>
          </div>
          {/* <div className="col-6">
            <Form layout="vertical" form={form} onFinish={handleSubmitPassword}>
              <Form.Item
                name="password"
                label="Password"
                rules={[
                  {
                    required: true,
                    message: "Please input your password!",
                  },
                ]}
                hasFeedback
              >
                <Input.Password />
              </Form.Item>

              <Form.Item
                name="confirm"
                label="Confirm Password"
                dependencies={["password"]}
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: "Please confirm your password!",
                  },
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        "The two passwords that you entered do not match!"
                      );
                    },
                  }),
                ]}
              >
                <Input.Password />
              </Form.Item>

              <Form.Item>
                <Button type="primary" htmlType="submit" loading={isLoading}>
                  Change Password
                </Button>
              </Form.Item>
            </Form>
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default View;
