const metaPage = {
  nextPage: null,
  page: 1,
  pageCount: null,
  perPage: null,
  previousPage: null,
  total: null,
};

export default {
  metaPage,
};
