import React, { useState } from "react";
import { Card, Button, Table, Space } from "antd";
import { Link } from "react-router-dom";
import { deleteConfirm } from "components";
import { MATERIAL_TYPE_VALUE } from "constant";
import ModalValidasi from "./modal-validasi";
import ModalResult from "./modal-result";

function View({ query, onDelete, isLoading, role, onValidate }) {
  const [isModalValidasiOpen, setModalValidasiOpen] = useState(false);
  const [isModalResultOpen, setModalResultOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const _handleValidate = async (values) => {
    await onValidate(selectedItem, values);
    setModalValidasiOpen(false);
  };

  const columns = [
    {
      title: "Judul",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      render: (type) => MATERIAL_TYPE_VALUE[type],
    },
    {
      title: "Rating",
      dataIndex: "rating",
      key: "rating",
    },
    {
      title: "Cover",
      key: "cover_link",
      dataIndex: "cover_link",
      render: (cover_link) => (
        <img src={cover_link} className="img-thumbnail" alt="" />
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Overview",
      dataIndex: "overview",
      key: "overview",
    },
    {
      title: "Action",
      key: "action",
      render: (record) => (
        <Space size="middle">
          {role === "validator" ? (
            <Button
              type="primary"
              onClick={() => {
                setModalValidasiOpen(!isModalValidasiOpen);
                setSelectedItem(record);
              }}
            >
              Validasi
            </Button>
          ) : (
            <>
              {role === "contributor" && (
                <Button
                  type="primary"
                  disabled={!record.validation_result}
                  onClick={() => {
                    setModalResultOpen(!isModalResultOpen);
                    setSelectedItem(record);
                  }}
                >
                  Hasil Validasi
                </Button>
              )}
              <Button
                type="primary"
                href={`/dashboard/material/form/${record.id}`}
              >
                Edit
              </Button>
              <Button
                type="primary"
                danger
                onClick={() =>
                  deleteConfirm({
                    type: "Material",
                    name: record.title,
                    confirmLoading: isLoading,
                    onDelete: () => onDelete(record.id),
                  })
                }
              >
                Delete
              </Button>
            </>
          )}
        </Space>
      ),
    },
  ];
  return (
    <>
      {isModalValidasiOpen && (
        <ModalValidasi
          onCancel={() => setModalValidasiOpen(false)}
          data={selectedItem}
          onOk={_handleValidate}
        />
      )}

      {isModalResultOpen && (
        <ModalResult
          toggle={() => setModalResultOpen(!isModalResultOpen)}
          result={selectedItem?.validation_result}
        />
      )}

      <div className="row">
        <div className="col-12">
          <Card
            title="Material"
            bordered={false}
            extra={
              role !== "validator" && (
                <Link to="/dashboard/material/form">
                  <Button type="primary">Tambah Baru</Button>
                </Link>
              )
            }
          >
            <Table
              columns={columns}
              dataSource={query.data}
              loading={query.isLoading}
              rowKey="id"
            />
          </Card>
        </div>
      </div>
    </>
  );
}

export default View;
