import React from "react";
import { Ads, Banner, Material } from "containers";
import { SectionHeader } from "components";
import { img } from "assets";
import { Link } from "react-router-dom";
import { MATERIAL_TYPE } from "constant";

function index() {
  return (
    <div className="">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="  pb-5">
              <div className="container ">
                <div className="row mb-5">
                  <div className="col-12">
                    <Banner />
                  </div>
                </div>
                <MaterialPopular type="VIDEO" title="Video" />
                <MaterialPopular type="BOOK" title="Book" />
                <MaterialPopular type="AUDIO" title="Audio" />
                <MaterialPopular type="HTML" title="HTML" />
                <div className="row">
                  <div className="col-12">
                    <Ads />
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <SectionHeader
                      title="Jenis Materi"
                      subtitle="Semua jenis materi yang ada di dalam website ini"
                    />
                  </div>
                </div>
                <div className="row mt-3">
                  <Link
                    to="/material?type=book-true"
                    className="col-6 col-md-3 text-center d-flex flex-column"
                  >
                    <img src={img.icBook} alt="buku" className="mb-4 " />
                    <b>Book</b>
                  </Link>
                  <Link
                    to="/material?type=video-true"
                    className="col-6 col-md-3 text-center d-flex flex-column"
                  >
                    <img src={img.icVideo} alt="video" className="mb-4 " />
                    <b>Video</b>
                  </Link>
                  <Link
                    to="/material?type=audio-true"
                    className="col-6 col-md-3 text-center d-flex flex-column"
                  >
                    <img src={img.icAudio} alt="audio" className="mb-4 " />
                    <b>Audio</b>
                  </Link>
                  <Link
                    to="/material?type=html-true"
                    className="col-6 col-md-3 text-center d-flex flex-column"
                  >
                    <img src={img.icHtml} alt="html" className="mb-4 " />
                    <b>HTML</b>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default index;

const MaterialPopular = ({ type, title }) => (
  <>
    <div className="row">
      <div className="col-12 ">
        <SectionHeader
          title={`Materi Terpopuler - ${title}`}
          subtitle={`Materi jenis ${title} yang paling banyak dilihat pengunjung`}
          button={
            <Link to={`/material?type=${type.toLowerCase()}-true`}>
              Lihat lebih banyak
            </Link>
          }
        />
      </div>
    </div>
    <div className="row mb-5">
      <div className="col-12 ">
        <Material sortBy="popular" limit={4} type={MATERIAL_TYPE[type]} />
      </div>
    </div>
  </>
);
