import React from "react";

function index({ title, subtitle, button }) {
  return (
    <div>
      <div className="d-flex justify-content-between align-items-center text-psb-dark">
        <div style={{ fontSize: 24, fontWeight: "bold" }}>{title}</div>
        {button}
      </div>
      <small className="text-muted">{subtitle}</small>
    </div>
  );
}

export default index;
