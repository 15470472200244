import React from "react";
import { Material } from "containers";
import { Checkbox, Collapse } from "antd";
import { MATERIAL_TYPE } from "constant";

function View({ onChangeType, types, searchText }) {
  let typesInArray = [];
  if (typeof types !== "undefined") {
    typesInArray = Object.keys(types)
      .filter((key) => types[key] === "true")
      .map((val) => MATERIAL_TYPE[val.toUpperCase()]);
  }
  const _isChecked = (type) => {
    if (typeof types === "undefined") return false;
    if (!types[type]) return false;
    if (types[type] === "false") return false;
    return true;
  };
  return (
    <div>
      <div className="bg-primary">
        <div
          className="container align-items-center d-flex"
          style={{ height: 150 }}
        >
          <div
            style={{ fontSize: 24, fontWeight: "bold" }}
            className="text-white"
          >
            Daftar Materi
          </div>
        </div>
      </div>
      <div className="container my-5">
        <div className="row">
          <div className="col-3">
            <div className="sticky-top pt-3">
              <div
                style={{ fontSize: 18, fontWeight: 600 }}
                className="text-primary mb-3"
              >
                Filter
              </div>
              <Collapse
                defaultActiveKey={["1", "2"]}
                expandIconPosition="right"
                bordered={false}
                ghost
              >
                <Collapse.Panel header="Tipe Materi" key="1">
                  <Checkbox
                    checked={_isChecked("book")}
                    onChange={(e) => onChangeType("book", e.target.checked)}
                  >
                    Book
                  </Checkbox>
                  <hr />
                  <Checkbox
                    checked={_isChecked("video")}
                    onChange={(e) => onChangeType("video", e.target.checked)}
                  >
                    Video
                  </Checkbox>
                  <hr />
                  <Checkbox
                    checked={_isChecked("audio")}
                    onChange={(e) => onChangeType("audio", e.target.checked)}
                  >
                    Audio
                  </Checkbox>
                  <hr />
                  <Checkbox
                    checked={_isChecked("html")}
                    onChange={(e) => onChangeType("html", e.target.checked)}
                  >
                    HTML
                  </Checkbox>
                  <hr />
                </Collapse.Panel>
                {/* <Collapse.Panel header="Rating" key="2">
                <Checkbox>
                  <Rate className="ant-rate-sm" value="5" />
                </Checkbox>
                <hr />
                <Checkbox>
                  <Rate className="ant-rate-sm" value="4" />
                </Checkbox>
                <hr />
                <Checkbox>
                  <Rate className="ant-rate-sm" value="3" />
                </Checkbox>
                <hr />
                <Checkbox>
                  <Rate className="ant-rate-sm" value="2" />
                </Checkbox>
                <hr />
                <Checkbox>
                  <Rate className="ant-rate-sm" value="1" />
                </Checkbox>
                <hr />
              </Collapse.Panel> */}
              </Collapse>
            </div>
          </div>
          <div className="col-9">
            {/* <div className="mb-3">
              <b>Menampilkan 47 Materi</b>
            </div> */}
            <Material
              colSize="4"
              types={typesInArray}
              showNumber
              searchText={searchText}
            />
          </div>
        </div>
        {/* <div className="row">
          <div className="col-12 d-flex justify-content-end">
            <Pagination defaultCurrent={1} total={50} />
          </div>
        </div> */}
      </div>
    </div>
  );
}

export default View;
