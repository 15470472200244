import React from "react";
import { Button, Card, List } from "antd";
import classnames from "classnames";

function View({ query, markHasRead }) {
  return (
    <div className="row">
      <div className="col-12">
        <Card
          title="Notification"
          bordered={false}
          extra={
            query.data.length > 0 && (
              <Button onClick={markHasRead} type="primary">
                Tandai Semua Sudah Dibaca
              </Button>
            )
          }
        >
          <List
            size="large"
            dataSource={query.data}
            renderItem={(record) => (
              <List.Item
                className={classnames({ "bg-accent-tint": !record.hasRead })}
              >
                {record.content}
                {record.types === "comment" && record.types === "rating" && (
                  <a
                    href={`/material/${record.material_slug}#${record.types}_${record.types_id}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    di material ini
                  </a>
                )}
              </List.Item>
            )}
          />
        </Card>
      </div>
    </div>
  );
}

export default View;
