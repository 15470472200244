import React, { useEffect } from "react";
import { Card, Form, Input, Button, Upload, Radio, Select } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload } from "@fortawesome/free-solid-svg-icons";
import { normFile } from "utils";
import { MATERIAL_TYPE } from "constant";
import { useState } from "react";

function View({ handleSubmit, isLoading, isEdit, data }) {
  const [form] = Form.useForm();
  const [type, setType] = useState(null);

  useEffect(() => {
    if (data) form.setFieldsValue({ ...data });
  }, [data, form]);

  useEffect(() => {
    setType(data.type);
  }, [data]);

  const _handleValueChanges = ({ type }) => {
    if (typeof type !== "undefined") setType(type);
  };

  return (
    <div className="row">
      <div className="col-12">
        <Card title="Material" bordered={false}>
          <Form
            layout="vertical"
            form={form}
            onFinish={handleSubmit}
            onValuesChange={_handleValueChanges}
          >
            <Form.Item label="Judul" name="title" required>
              <Input />
            </Form.Item>
            <Form.Item label="Overview (Description)" name="overview" required>
              <Input.TextArea rows={4} />
            </Form.Item>
            <Form.Item name="type" label="Tipe" required>
              <Radio.Group>
                {Object.keys(MATERIAL_TYPE).map((key) => (
                  <Radio key={MATERIAL_TYPE[key]} value={MATERIAL_TYPE[key]}>
                    {key}
                  </Radio>
                ))}
              </Radio.Group>
            </Form.Item>

            {(type === MATERIAL_TYPE.VIDEO || type === MATERIAL_TYPE.AUDIO) && (
              <Form.Item
                label={`${
                  type === MATERIAL_TYPE.VIDEO ? "Youtube" : "Anchor.fm"
                } Embed Code`}
                name="content"
                required
              >
                <Input.TextArea />
              </Form.Item>
            )}

            {(type === MATERIAL_TYPE.BOOK || type === MATERIAL_TYPE.HTML) && (
              <Form.Item label="File" required={!isEdit}>
                {type === MATERIAL_TYPE.BOOK && isEdit && (
                  <>
                    <Button href={data.content} target="_blank">
                      Lihat/Unduh File
                    </Button>
                    <br />
                    <br />
                  </>
                )}
                <Form.Item
                  name="file"
                  valuePropName="fileList"
                  getValueFromEvent={normFile}
                  required={!isEdit}
                  label="File"
                  noStyle
                >
                  <Upload
                    accept={
                      type === MATERIAL_TYPE.HTML5
                        ? "application/zip"
                        : "application/pdf"
                    }
                    beforeUpload={() => false}
                    multiple={false}
                  >
                    <Button>
                      <FontAwesomeIcon icon={faUpload} className="mr-2" />
                      {isEdit ? "Ganti " : "Pilih "}{" "}
                      {type === MATERIAL_TYPE.HTML5 ? ".zip File" : " PDF"}
                    </Button>
                  </Upload>
                </Form.Item>
              </Form.Item>
            )}

            <Form.Item label="Cover" required={!isEdit}>
              {data?.cover_link && (
                <img
                  src={data.cover_link}
                  className="img-thumbnail d-block mb-1"
                  alt=""
                />
              )}
              <Form.Item
                name="cover"
                valuePropName="fileList"
                getValueFromEvent={normFile}
                required={!isEdit}
                label="Cover"
                noStyle
              >
                <Upload
                  accept="image/*"
                  beforeUpload={() => false}
                  multiple={false}
                >
                  <Button>
                    <FontAwesomeIcon icon={faUpload} className="mr-2" />
                    {data?.cover_link ? "Ganti Cover" : "Pilih Cover"}
                  </Button>
                </Upload>
              </Form.Item>
            </Form.Item>
            <Form.Item label="Keywords" name="keywords">
              <Select
                mode="tags"
                style={{ width: "100%" }}
                placeholder="Masukan keywords"
                autoCapitalize="none"
              ></Select>
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit" loading={isLoading}>
                Simpan
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </div>
    </div>
  );
}

export default View;
