import React from "react";
import View from "./material-list-view";
import { useQueryParam, ObjectParam, StringParam } from "use-query-params";

function Controller() {
  const [types, setTypes] = useQueryParam("type", ObjectParam);
  const [searchText, setSearchText] = useQueryParam("search", StringParam);

  const _handleChangeType = (type, val) => {
    setSearchText(undefined);
    setTypes({ ...types, [type]: val });
  };

  return (
    <View
      onChangeType={_handleChangeType}
      types={types}
      searchText={searchText}
    />
  );
}

export default Controller;
