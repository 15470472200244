import React from "react";
import { Switch, BrowserRouter, Route } from "react-router-dom";
import { routes } from "config";
import { SuperProvider } from "context";
import { PrivateRoute } from "components";
import { QueryParamProvider } from "use-query-params";
import { MetaSetting } from "containers";

function App() {
  return (
    <SuperProvider>
      <BrowserRouter>
        <QueryParamProvider ReactRouterRoute={Route}>
          <Switch>
            {routes.layouts.map((route) => {
              if (route.isPublic) {
                return (
                  <Route
                    path={route.path}
                    component={route.component}
                    key={route.path}
                  />
                );
              }
              return (
                <PrivateRoute
                  path={route.path}
                  component={route.component}
                  key={route.path}
                  roles={route.roles}
                />
              );
            })}
          </Switch>
        </QueryParamProvider>
      </BrowserRouter>
      <MetaSetting />
    </SuperProvider>
  );
}

export default App;
