import React, { useEffect, useRef, useState, useContext } from "react";
import axios from "axios";
import { API } from "config";
import { AuthContext } from "context/auth";
import { useHistory } from "react-router-dom";
import View from "./login-view";

const Controller = () => {
  const [isLoading, setLoading] = useState(false);
  const source = useRef(null);
  const authCtx = useContext(AuthContext);
  const history = useHistory();

  const _handleLogin = async (rspGoogle) => {
    try {
      setLoading(true);
      source.current = axios.CancelToken.source();
      const { access_token } = rspGoogle.wc;
      const rsp = await API.auth.loginWithGoogle(
        source.current.token,
        access_token
      );

      authCtx.login(rsp, 30);
      setLoading(false);
    } catch (err) {
      console.log("err: ", err);
      setLoading(false);
    }
  };

  useEffect(() => {
    const _checkIsLogin = () => {
      if (authCtx.isLogin()) {
        if (
          authCtx.user?.role === "admin" ||
          authCtx.user?.role === "validator"
        ) {
          history.push("/dashboard");
          return;
        }
        history.push("/");
      }
    };

    _checkIsLogin();
  }, [authCtx, history]);

  useEffect(() => {
    return () => {
      if (source.current) {
        source.current.cancel("request canceled");
      }
    };
  }, []);
  return <View isLoading={isLoading} handleLogin={_handleLogin} />;
};

export default Controller;
