import React, { useRef, useEffect } from "react";
import { API } from "config";
import axios from "axios";
import { CardMaterial } from "components";
import { useState } from "react";
import { Empty, Spin } from "antd";

function Component({
  colSize,
  types,
  type,
  sortBy,
  contributorId,
  showNumber,
  searchText,
  limit,
}) {
  const [data, setData] = useState([]);
  const [isLoading, setLoading] = useState([]);
  const source = useRef(null);

  useEffect(() => {
    const _loadData = async () => {
      setLoading(true);
      try {
        source.current = axios.CancelToken.source();
        if (searchText) {
          const { data } = await API.material.listByTitle(
            source.current.token,
            searchText
          );
          setData(data);
          setLoading(false);
          return;
        }

        if (types?.length > 0) {
          const { data } = await API.material.listByType(
            source.current.token,
            JSON.stringify(types)
          );
          setData(data);
          setLoading(false);
          return;
        }

        if (contributorId) {
          const { data } = await API.material.listByContributor(
            source.current.token,
            contributorId
          );
          setData(data);
          setLoading(false);
          return;
        }

        if (sortBy === "popular") {
          const { data } = await API.material.listPopular(
            source.current.token,
            type,
            limit
          );
          setData(data);
          setLoading(false);
          return;
        }

        const { data } = await API.material.list(source.current.token);
        setData(data);
        setLoading(false);
        return;
      } catch (error) {
        console.log("error: ", error);
        setLoading(false);
        setData([]);
      }
    };
    _loadData();
  }, [contributorId, limit, searchText, sortBy, type, types]);

  colSize = colSize || "3";

  return (
    <>
      {showNumber && (
        <div className="mb-3">
          <b>Menampilkan {data.length} Materi</b>
        </div>
      )}
      <div className="row">
        {isLoading && (
          <div className="col-12 mt-5 text-center">
            <Spin />
          </div>
        )}
        {!isLoading && data.length === 0 && (
          <div className="col-12 mt-5 text-center">
            <Empty description="Belum ada materi" />
          </div>
        )}
        {data.map((val) => (
          <div
            className={`col-12 col-md-6 col-lg-${colSize} mb-3`}
            key={val.id}
          >
            <CardMaterial
              slug={val.slug}
              cover={val.cover_link}
              type={val.type}
              viewCount={val.view_count}
              title={val.title}
              description={val.overview}
              rating={val.rating}
            />
          </div>
        ))}
      </div>
    </>
  );
}

export default Component;
