import React, { useRef, useEffect, useState } from "react";
import { API } from "config";
import axios from "axios";
import View from "./contributor-view";
import { useParams } from "react-router-dom";

function Controller() {
  const params = useParams();
  const [data, setData] = useState([]);
  const [isLoading, setLoading] = useState([]);
  const source = useRef(null);

  useEffect(() => {
    const _loadData = async () => {
      setLoading(true);
      try {
        source.current = axios.CancelToken.source();
        const data = await API.user.getByID(source.current.token, params.id);
        console.log("data: ", data);
        setData(data);
        setLoading(false);
        return;
      } catch (error) {
        console.log("error: ", error);
        setLoading(false);
        setData([]);
      }
    };
    _loadData();
  }, [params]);
  return <View data={data} isLoading={isLoading} />;
}

export default Controller;
