import { API, env } from "config";

const upload = (
  cancelToken,
  file,
  filename,
  bucket = process.env.REACT_APP_DEFAULT_BUCKET
) => {
  filename = `${env.FOLDER_PREFIX}/${filename}`.replace(
    /[&\\#,+()$~%'":*?<>{}]/g,
    "_"
  );

  let payload = new FormData();
  payload.append("file", file);

  return API.file.upload(cancelToken, payload, filename, bucket);
};

export default { upload };
