import React, { useRef } from "react";
import { useQuery } from "react-query";
import { API } from "config";
import axios from "axios";
import { Carousel } from "antd";

function Component() {
  const source = useRef(null);
  source.current = axios.CancelToken.source();
  const query = useQuery(
    "ads",
    async () => {
      const rsp = await API.ads.list(source.current.token);
      return rsp.data;
    },
    { initialData: [], initialStale: true }
  );

  if (query.data.length === 0) {
    return null;
  }

  return (
    <Carousel autoplay={true} className="carousel-ads">
      {query.data.map((val) => (
        <Item img={val.image_link} key={val.id} />
      ))}
    </Carousel>
  );
}

const Item = ({ img }) => <img src={img} alt="" className="img-fluid w-100" />;

export default Component;
