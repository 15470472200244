import React from "react";
import { useContext } from "react";
import { SiteSettingContext } from "context/site-setting";
import { useEffect } from "react";
import { API } from "config";
import { useState } from "react";
import { Helmet } from "react-helmet";

const getFaviconEl = () => {
  return document.getElementById("favicon");
};

function Component() {
  const { value, setValue } = useContext(SiteSettingContext);
  const [isLoaded, setLoaded] = useState(false);

  useEffect(() => {
    const _loadData = async () => {
      const data = await API.setting.get();
      setLoaded(true);
      setValue(data);
    };
    if (!isLoaded) _loadData();
  }, [isLoaded, setValue]);

  useEffect(() => {
    if (value.favicon_link) {
      const favicon = getFaviconEl();
      favicon.href = value.favicon_link;
    }
  }, [value.favicon_link]);

  return (
    <>
      <Helmet>
        <title>{value.site_name}</title>
        <meta name="description" content={value.meta_description} />
        <meta name="keywords" content={value.meta_keyword} />
        <meta property="og:title" content={value.site_name} />
        <link rel="apple-touch-icon" href={value.favicon_link} />
        <link rel="icon" href={value.favicon_link} />
      </Helmet>
    </>
  );
}

export default Component;
