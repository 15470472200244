import React, { useContext } from "react";
import {
  Alert,
  Comment,
  Avatar,
  Form,
  Button,
  List,
  Input,
  Tooltip,
} from "antd";
import { AuthContext } from "context/auth";
import moment from "moment";

const { TextArea } = Input;

function View({ handleSubmit, comments }) {
  const authCtx = useContext(AuthContext);
  const [form] = Form.useForm();
  const _onSubmit = async (values) => {
    await handleSubmit(values);
    form.resetFields();
  };

  return (
    <>
      <Comment
        avatar={
          authCtx.isLogin() ? (
            <Avatar src={authCtx.user.picture} alt={authCtx.user.name} />
          ) : (
            <Avatar />
          )
        }
        content={
          <Editor
            disabled={!authCtx.isLogin()}
            onSubmit={_onSubmit}
            form={form}
          />
        }
      />
      <CommentList comments={comments} />
    </>
  );
}

const CommentList = ({ comments }) => (
  <List
    dataSource={comments}
    header={`${comments.length} ${comments.length > 1 ? "replies" : "reply"}`}
    itemLayout="horizontal"
    renderItem={({ content, createdAt, user_id }) => (
      <Comment
        author={user_id.name}
        avatar={<Avatar src={user_id.picture} />}
        content={content}
        datetime={
          <Tooltip
            title={moment(createdAt).format("dddd, DD MMM YYYY HH:mm:ss")}
          >
            <span>{moment(createdAt).fromNow()}</span>
          </Tooltip>
        }
      />
    )}
  />
);

const Editor = ({ onSubmit, submitting, disabled, form }) => {
  return (
    <Form form={form} onFinish={onSubmit}>
      {disabled && (
        <Alert
          message="Masuk untuk memberikan komentar"
          type="error"
          className="mb-2"
        />
      )}
      <Form.Item
        name="content"
        rules={[{ required: true, message: "Anda belum menulis komentar" }]}
      >
        <TextArea rows={4} disabled={disabled} />
      </Form.Item>
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          loading={submitting}
          disabled={disabled}
        >
          Add Comment
        </Button>
      </Form.Item>
    </Form>
  );
};

export default View;
