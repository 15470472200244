import React, { useEffect } from "react";
import { Card, Form, Input, Button, Upload } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload } from "@fortawesome/free-solid-svg-icons";
import { normFile } from "utils";

function View({ handleSubmit, isLoading, isEdit, data }) {
  const [form] = Form.useForm();

  useEffect(() => {
    if (data) form.setFieldsValue({ ...data });
  }, [data, form]);

  return (
    <div className="row">
      <div className="col-12">
        <Card title="Tambah Validator" bordered={false}>
          <Form layout="vertical" form={form} onFinish={handleSubmit}>
            <Form.Item label="Nama Lengkap" name="name" hasFeedback required>
              <Input />
            </Form.Item>
            <Form.Item
              label="Email"
              name="email"
              rules={[{ type: "email" }]}
              hasFeedback
              required
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="password"
              label="Password"
              rules={[
                {
                  required: true,
                  message: "Please input your password!",
                },
              ]}
              hasFeedback
            >
              <Input.Password />
            </Form.Item>

            <Form.Item
              name="confirm"
              label="Confirm Password"
              dependencies={["password"]}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: "Please confirm your password!",
                },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      "The two passwords that you entered do not match!"
                    );
                  },
                }),
              ]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item label="Photo" required={!isEdit}>
              {data?.picture && (
                <img
                  src={data.picture}
                  className="img-thumbnail d-block mb-1"
                  style={{ height: 150 }}
                  alt=""
                />
              )}
              <Form.Item
                name="pic"
                valuePropName="fileList"
                getValueFromEvent={normFile}
                required={!isEdit}
                label="Picture"
                noStyle
              >
                <Upload
                  accept="image/*"
                  beforeUpload={() => false}
                  multiple={false}
                >
                  <Button>
                    <FontAwesomeIcon icon={faUpload} className="mr-2" />
                    {data?.picture ? "Ganti" : "Pilih"} Photo
                  </Button>
                </Upload>
              </Form.Item>
            </Form.Item>
            <Form.Item label="Pekerjaan" name="job">
              <Input />
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit" loading={isLoading}>
                Simpan
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </div>
    </div>
  );
}

export default View;
