import React from "react";
import { Card, Button, Table, Space } from "antd";
import { Link } from "react-router-dom";
import { deleteConfirm } from "components";

function View({ query, onDelete, isLoading }) {
  const columns = [
    {
      title: "Image",
      key: "image_link",
      dataIndex: "image_link",
      render: (image_link) => (
        <img src={image_link} className="img-thumbnail" alt="" />
      ),
    },
    {
      title: "Link to",
      key: "link_to",
      dataIndex: "link_to",
    },
    {
      title: "Action",
      key: "action",
      render: (record) => (
        <Space size="middle">
          <Button type="primary" href={`/dashboard/ads/form/${record.id}`}>
            Edit{" "}
          </Button>
          <Button
            type="primary"
            danger
            onClick={() =>
              deleteConfirm({
                type: "Ads",
                name: record.title,
                confirmLoading: isLoading,
                onDelete: () => onDelete(record.id),
              })
            }
          >
            Delete
          </Button>
        </Space>
      ),
    },
  ];
  return (
    <div className="row">
      <div className="col-12">
        <Card
          title="Ads"
          bordered={false}
          extra={
            <Link to="/dashboard/ads/form">
              <Button type="primary">Tambah Baru</Button>
            </Link>
          }
        >
          <Table
            columns={columns}
            dataSource={query.data}
            loading={query.isLoading}
            rowKey="id"
          />
        </Card>
      </div>
    </div>
  );
}

export default View;
